import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NavLink } from './DrawerNavigation';

const TitleUpdater = ({ setCurrentLink }: { setCurrentLink: React.Dispatch<React.SetStateAction<NavLink>> }) => {
    const location = useLocation();

    useEffect(() => {
        const routeTitles: { [key: string]: string } = {
            '/login': 'Login',
            '/register': 'Register',
            '/forgot-password': 'Forgot Password',
            '/reset-password': 'Reset Password',
            '/jobs/dashboard': 'Jobs Dashboard',
            '/jobs/ready': 'Mark Jobs Ready',
            '/jobs/submit': 'Submit Jobs',
            '/logout': 'Logout',
            '/': 'Login',
            '/jobs/': 'Individual Job Details',
        };

        var title = routeTitles[location.pathname] || 'Untitled';

        if (title == 'Untitled' && location.pathname.includes('/jobs/')) {
            title = 'Individual Job Details';
        }

        document.title = title;
        
        setCurrentLink({ to: location.pathname, text: title });
    }, [location, setCurrentLink]);

    return null;
};

export default TitleUpdater;
